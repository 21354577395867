/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";

import MdslApi from "./mdsl.api";
import UserApi from "./user.api";

const getNetworksQueryOptions = () =>
  queryOptions({
    queryKey: ["networks"],
    queryFn: () => MdslApi.getNetworks(),
    retry: 2,
  });

const getSingleNetworkQueryOptions = (networkId: string | undefined) =>
  queryOptions({
    queryKey: ["networks", networkId],
    queryFn: () => MdslApi.getNetworkById(networkId!),
    enabled: !!networkId,
  });

const getAvailableFrontendFeatures = () =>
  queryOptions({
    queryKey: ["frontendFeatures"],
    queryFn: () =>
      MdslApi.getFrontendFeatures().then((res) =>
        res.sort((a, b) => a.feature!.localeCompare(b.feature!))
      ),
  });

const getTariffPlansQueryOptions = () =>
  queryOptions({
    queryKey: ["tariffPlans"],
    queryFn: () => MdslApi.getTariffPlans(),
  });

const getSingleTariffPlanQueryOptions = (tariffPlanId: string | undefined) =>
  queryOptions({
    queryKey: ["tariffPlans", tariffPlanId],
    queryFn: () => MdslApi.getTariffPlanById(tariffPlanId!),
    enabled: !!tariffPlanId,
  });

const getNetworkTariffPlansQueryOptions = (networkId: string | undefined) =>
  queryOptions({
    queryKey: ["tariffPlans", "networks", networkId],
    queryFn: () => MdslApi.getNetworkTariffPlans(networkId),
    enabled: !!networkId,
  });

const getUserProfileQueryOptions = (userId: string | undefined) =>
  queryOptions({
    queryKey: ["userProfile", userId],
    queryFn: () => UserApi.getUserProfile(),
    enabled: !!userId,
  });

const getGroupsQueryOptions = () =>
  queryOptions({
    queryKey: ["groups"],
    queryFn: () => UserApi.getUserGroups(),
  });

const getPoliciesQueryOptions = () =>
  queryOptions({
    queryKey: ["policies"],
    queryFn: () => UserApi.getPolicies(),
  });

const getPolicyByIdQueryOptions = (policyId: string | undefined) =>
  queryOptions({
    queryKey: ["policy", policyId],
    queryFn: () => UserApi.getPolicyById(policyId!),
    enabled: !!policyId,
  });

const getGroupByIdQueryOptions = (groupId: string | undefined) =>
  queryOptions({
    queryKey: ["group", groupId],
    queryFn: () => UserApi.getGroupById(groupId!),
    enabled: !!groupId,
  });

const addNetworkToPolicyByIdQueryOptions = (
  policyId: string | undefined,
  networkId: string | undefined
) =>
  queryOptions({
    queryKey: ["policy", policyId],
    queryFn: () => UserApi.addNetworkToPolicyById(policyId!, networkId!),
    enabled: !!policyId && !!networkId,
  });

export {
  getNetworksQueryOptions,
  getSingleNetworkQueryOptions,
  getAvailableFrontendFeatures,
  getTariffPlansQueryOptions,
  getSingleTariffPlanQueryOptions,
  getNetworkTariffPlansQueryOptions,
  getUserProfileQueryOptions,
  getGroupsQueryOptions,
  getPoliciesQueryOptions,
  getPolicyByIdQueryOptions,
  addNetworkToPolicyByIdQueryOptions,
  getGroupByIdQueryOptions,
};
