/* eslint-disable @typescript-eslint/no-explicit-any */
export function toSnakeCase(data: object | any[]): any {
  if (data === null || typeof data !== "object") {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map((item) => toSnakeCase(item));
  }

  const result: { [key: string]: any } = {};
  Object.keys(data).forEach((key) => {
    const newKey = key.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`);
    result[newKey] = toSnakeCase(data[key]);
  });

  return result;
}
