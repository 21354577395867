import {
  ExtendedTariffPlan,
  FrontendFeature,
  Group,
  Network,
  NetworkTariffPlan,
  TariffPlan,
  UserPolicies,
} from "@core/models";

import useAuthStore from "../stores/auth.store";
import axiosClient, { CustomAxiosDefaults } from "./apiClient";

export class MdslApi {
  setBaseUrl(baseUrl: string, shouldCamelCaseResponse = false): void {
    axiosClient.defaults.baseURL = baseUrl;
    (axiosClient.defaults as CustomAxiosDefaults).shouldCamelCaseResponse = shouldCamelCaseResponse;
  }

  addPolicyToNetwork(arg0: {
    networkUid: string | null;
    policyUid: string | null;
    validFrom: string | null | undefined;
    validTo: string | null | undefined;
  }): any {
    throw new Error("Method not implemented.");
  }

  async getAuthToken(): Promise<string> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useAuthStore.getState().getToken!();
  }

  async getNetworks(): Promise<Network[]> {
    const authToken = await this.getAuthToken();
    return axiosClient
      .get("/networks", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data.collection);
  }

  async getNetworkById(networkUid: string): Promise<Network> {
    const authToken = await this.getAuthToken();
    return axiosClient
      .get(`/networks/${networkUid}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data);
  }

  async getAllGroups(): Promise<Group[]> {
    const authToken = await this.getAuthToken();
    return axiosClient
      .get("/groups", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data.collection);
  }

  async getGroup(groupUid: string): Promise<Group> {
    const authToken = await this.getAuthToken();
    return axiosClient
      .get(`/groups/${groupUid}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data);
  }

  async createNetworkGroup(
    name: string | undefined,
    managementLevel: number | undefined,
    resourceLevel: number | undefined
  ): Promise<Group> {
    const existingGroups = await this.getAllGroups();
    if (!name) {
      throw new Error("Group name cannot be empty");
    }
    if (!managementLevel) {
      throw new Error("Management level cannot be empty");
    }
    if (!resourceLevel) {
      throw new Error("Resource level cannot be empty");
    }
    if (!existingGroups.map((group) => group.name).includes(name)) {
      // Surely this should happen in the BE?
      const authToken = await this.getAuthToken();
      return axiosClient.post(
        "/groups",
        {
          name,
          management_level: managementLevel,
          resource_level: resourceLevel,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
    } else {
      console.warn("Group already exists");
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return existingGroups.find((group) => group.name === name)!;
    }
  }

  async createNetworkPolicy(
    groupUid: string | undefined,
    policyName: string | undefined
  ): Promise<UserPolicies> {
    if (!groupUid) {
      throw new Error("Group UID cannot be empty");
    }
    if (!policyName) {
      throw new Error("Policy name cannot be empty");
    }
    const groupData = await this.getGroup(groupUid);
    const policiesInGroup = groupData.policies || [];

    if (!policiesInGroup.some((p) => p.name === policyName)) {
      const authToken = await this.getAuthToken();
      return axiosClient.post(
        `/groups/${groupUid}/policies`,
        {
          name: policyName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
    } else {
      console.warn("Policy already exists");
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return policiesInGroup.find((p) => p.name === policyName)!;
    }
  }

  // createNetwork
  async createNetwork(networkName: string, policyUid: string | undefined): Promise<Network> {
    const authToken = await this.getAuthToken();
    // if 409, network already exists with the same name
    if (!networkName) {
      throw new Error("Network name cannot be empty");
    }
    if (!policyUid) {
      throw new Error("Policy UID cannot be empty");
    }

    return axiosClient
      .post(
        `/policies/${policyUid}/networks`,
        {
          name: networkName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => res.data.data)
      .catch((error) => {
        if (error.response.status === 409) {
          console.warn("Network already exists");
          return { error: "Network already exists" };
        }
        throw error;
      })
      .then((res) => {
        if (res.error === "Network already exists") {
          // eslint-disable-next-line promise/no-nesting
          return this.getNetworks().then(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (networks) => networks.find((network) => network.name === networkName)!
          );
        }
        return res;
      });
  }

  // update DisplayName
  // data = {"display_name": display_name}
  // self.patch("networks", [nuid], json = data, accept = accept)
  async updateNetworkDisplayName(networkUid: string, displayName: string): Promise<Network> {
    if (!displayName) {
      throw new Error("Display name cannot be empty");
    }
    if (!networkUid) {
      throw new Error("Network UID cannot be empty");
    }
    const authToken = await this.getAuthToken();
    return axiosClient
      .patch(
        `/networks/${networkUid}`,
        {
          display_name: displayName,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => res.data.data);
  }

  // /v4/config/frontend-features
  async getFrontendFeatures(): Promise<FrontendFeature[]> {
    const authToken = await this.getAuthToken();
    return axiosClient
      .get("/config/frontend-features", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data.collection);
  }

  // /v4/admin/tariff-plans
  async getTariffPlans(): Promise<TariffPlan[]> {
    const authToken = await this.getAuthToken();
    return axiosClient
      .get("/admin/tariff-plans", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data.collection);
  }

  // /v4/admin/tariff-plans/{tariff_plan_uid}
  async getTariffPlanById(tariffPlanUid: string): Promise<ExtendedTariffPlan> {
    const authToken = await this.getAuthToken();
    return axiosClient
      .get(`/admin/tariff-plans/${tariffPlanUid}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data);
  }

  // /v4/admin/tariff-plans/networks/{networkUID}
  async getNetworkTariffPlans(networkUid: string | undefined): Promise<NetworkTariffPlan[]> {
    if (!networkUid) {
      throw new Error("Network UID cannot be empty");
    }
    const authToken = await this.getAuthToken();
    return axiosClient
      .get(`/admin/tariff-plans/networks/${networkUid}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => res.data.data.collection);
  }

  // /v4/admin/tariff-plans/{tariffPlanUID}/networks/{networkUID}
  async addTariffPlanToNetwork(arg0: {
    networkUid: string | null | undefined;
    tariffPlanUid: string | null | undefined;
    contractType: string | null;
    validFrom: string | null | undefined;
    validTo: string | null | undefined;
  }): Promise<any> {
    const { networkUid, tariffPlanUid, validFrom, validTo, contractType } = arg0;
    if (!networkUid) {
      throw new Error("Network UID cannot be empty");
    }
    if (!tariffPlanUid) {
      throw new Error("Tariff Plan UID cannot be empty");
    }
    // Need at least validFrom
    if (!validFrom) {
      throw new Error("Valid From cannot be empty");
    }
    const authToken = await this.getAuthToken();
    return axiosClient
      .put(
        `/admin/tariff-plans/${tariffPlanUid}/networks/${networkUid}`,
        {
          valid_from: validFrom,
          valid_to: validTo,
          contract_type: contractType,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
      .then((res) => res.data.data);
  }
}

export default new MdslApi();
