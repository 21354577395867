import { useState } from "react";
import { Box, Chip, Divider, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createFileRoute, useParams } from "@tanstack/react-router";
import { AddTariffPlanToNetwork, UnstyledLink } from "@ui/components";
import { handleALL_CAPS_HELL, uidToHexColor } from "@utils/index";
import { DateTime } from "luxon";

import MdslApi from "@core/apis/mdsl.api";
import { getSingleTariffPlanQueryOptions } from "@core/apis/queries";
import { Network } from "@core/models";

const SingleTariffPlan = () => {
  const { queryClient } = Route.useRouteContext();
  const [isAddTariffToNetworkOpen, setIsAddTariffPlanToNetworkOpen] = useState(false);
  const { tariffPlanId } = useParams({ strict: false });
  const { data: tariffPlanData } = useQuery(getSingleTariffPlanQueryOptions(tariffPlanId));

  // Server State + Mutations
  const { mutate: addTariffPlanToNetwork } = useMutation({
    mutationFn: async ({
      networkUid,
      tariffPlanUid,
      validFrom,
      validTo,
      contractType,
    }: {
      networkUid: string;
      tariffPlanUid: string;
      validFrom: DateTime | null;
      validTo: DateTime | null;
      contractType: string;
    }) => {
      return MdslApi.addTariffPlanToNetwork({
        networkUid,
        tariffPlanUid,
        validFrom: validFrom?.startOf("day").toISO(),
        validTo: validTo?.startOf("day").toISO(),
        contractType,
      });
    },
    onSettled: (data, error, variables) =>
      queryClient.invalidateQueries({
        queryKey: ["tariffPlans", "networks", variables.networkUid],
      }),
  });

  const handleAddTariffPlanToNetwork = async (updateValues?: {
    networkUid: string;
    tariffPlanUid: string;
    validFrom: DateTime | null;
    validTo: DateTime | null;
    contractType: string;
  }) => {
    if (updateValues) {
      await addTariffPlanToNetwork({
        ...updateValues,
      });
    }
    setIsAddTariffPlanToNetworkOpen(false);
  };

  return (
    <Box>
      <Typography variant="h2">Tariff Plan</Typography>
      {tariffPlanData && (
        <Stack>
          <Divider
            sx={{ backgroundColor: uidToHexColor(tariffPlanData.uid), borderBottomWidth: "thick" }}
            variant="middle"
          />
          <Box py={2}>
            <Typography variant="h3">{tariffPlanData.name}</Typography>
            <Typography>{tariffPlanData.uid}</Typography>
          </Box>
          {/* Feature Chips */}
          <Typography variant="h4">Included Features</Typography>
          <Box display="flex" flexWrap="wrap" gap={1} justifyContent="stretch" p={2}>
            {tariffPlanData.frontendFeatures.map((feature: string) => (
              <Tooltip key={feature} title={feature}>
                <Chip key={feature} label={handleALL_CAPS_HELL(feature)} color="info" />
              </Tooltip>
            ))}
          </Box>
          {/* Networks */}
          <Typography variant="h4">Networks</Typography>
          <Box display="flex" flexWrap="wrap" gap={1} justifyContent="stretch" p={2}>
            {tariffPlanData.networks.map((network: Network) => (
              <Tooltip key={network.uid} title={network.displayName}>
                <Chip
                  key={network.uid}
                  label={network.displayName}
                  color="primary"
                  component={UnstyledLink}
                  to={`/app/networks/${network.uid}`}
                  clickable
                />
              </Tooltip>
            ))}{" "}
            <AddTariffPlanToNetwork
              isOpen={isAddTariffToNetworkOpen}
              closeModal={handleAddTariffPlanToNetwork}
              tariffPlanUid={tariffPlanData.uid}
            />
            <Tooltip title="Add Network">
              <Chip
                label="+"
                color="success"
                clickable
                onClick={() => setIsAddTariffPlanToNetworkOpen(true)}
              />
            </Tooltip>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export const Route = createFileRoute("/app/_layout/tariff-plans/$tariffPlanId")({
  component: SingleTariffPlan,
  loader: async (opts) => {
    // Fancy little piece of code that ensures the networks data is loaded before rendering the component
    try {
      await opts.context.queryClient.ensureQueryData(
        getSingleTariffPlanQueryOptions(opts.params.tariffPlanId)
      );
    } catch (error) {
      console.error("Error loading data", error);
    }
  },
});
