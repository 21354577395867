import { useState } from "react";
import {
  AppBar,
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { faCardDiamond } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { UnstyledLink } from "@ui/components";

const AvatarIcon = () => {
  const { auth } = Route.useRouteContext();
  const [avatarEl, setAvatarEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(avatarEl);
  const id = open ? "simple-popover" : undefined;

  const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };
  return (
    <>
      <Button aria-describedby={id} onClick={handleAvatarClick}>
        <Avatar sx={{ mx: 2 }} src={auth?.user?.picture} alt={auth?.user?.name} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={avatarEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton>
              <UnstyledLink to="/app/profile" style={{ textDecoration: "none" }}>
                <ListItemText primary="Profile" />
              </UnstyledLink>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={() => auth?.logout()}>
              <ListItemText primary="Log out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

const AppLayout = () => {
  // Client State
  const theme = useTheme();

  const { pageTitle, pageIcon } = {
    pageTitle: "Admin Dashboard",
    pageIcon: faCardDiamond,
  };

  return (
    <Grid container direction="column" flexGrow={1} height="100vh">
      <Grid item>
        <AppBar
          elevation={0}
          position="sticky"
          sx={{
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
          }}
        >
          <Toolbar>
            <Grid container justifyContent="space-between" alignItems="center">
              {/* Drawer top left */}
              {/* <PageAppBar filterAffects={filterAffects} /> */}

              <Grid item xs={2}>
                {/* <FontAwesomeIcon icon={pageIcon} size="2x" />
              <Typography variant="h4" data-testid="page-title">
                {pageTitle}
              </Typography> */}
              </Grid>

              {/* page title */}
              <Grid item xs={8}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  component={UnstyledLink}
                  to="/app"
                >
                  <FontAwesomeIcon icon={pageIcon} size="2x" />
                  <Typography variant="h4" data-testid="page-title" ml={1}>
                    {pageTitle}
                  </Typography>
                </Box>
              </Grid>

              {/* Setting top right */}
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  <AvatarIcon />
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Grid>

      {/* Service Worker related notifications */}
      {/* <ServiceWorkerPrompt /> */}
      <Grid item xs overflow="auto">
        <Box m={2} sx={{ backgroundColor: "grey.300" }} borderRadius={2} p={2} overflow="auto">
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  );
};

export const Route = createFileRoute("/app/_layout")({
  component: () => <AppLayout />,
  // Before loading, authenticate the user via our auth context
  // This will also happen during prefetching (e.g. hovering over links, etc)
  beforeLoad: async ({ context, location }) => {
    // If the user is logged out, redirect them to the login page
    if (!context.auth?.isAuthenticated && !context.auth?.isLoading) {
      // Redirect to the login page
      throw redirect({
        to: "/login",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }
  },
  loader: async (opts) => {},
});
