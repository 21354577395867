import { Box, Button, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Stack } from "@mui/system";
import { faNetworkWired } from "@fortawesome/pro-light-svg-icons";
import { faBowArrow, faMoneyBill } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFileRoute, Link } from "@tanstack/react-router";

import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

export const Route = createFileRoute("/app/_layout/")({
  component: App,
});

function App() {
  return (
    <Box height="100%" width="100%" zIndex={-1} p={2}>
      <Box borderRadius={4} sx={{ backgroundColor: "grey.300" }} p={4} mb={4}>
        <Typography variant="h2">Welcome to the Admin App!</Typography>
        <Typography variant="subtitle2" pb={2}>
          This is the Admin App, where you can manage various aspects of the Utilifeed ecosystem.
        </Typography>

        <Box borderRadius={4} sx={{ backgroundColor: "grey.200" }} px={4}>
          <Grid2 container spacing={2} py={1}>
            <Grid2 xs={2}>
              <Typography variant="h3" pb={2}>
                Manage:
              </Typography>
              <Stack spacing={2}>
                <Button
                  variant="contained"
                  component={Link}
                  to="/app/networks/"
                  startIcon={<FontAwesomeSvgIcon icon={faNetworkWired} />}
                >
                  Networks
                </Button>
                <Button
                  variant="contained"
                  component={Link}
                  to="/app/tariff-plans/"
                  startIcon={<FontAwesomeSvgIcon icon={faMoneyBill} />}
                >
                  Tariff Plans
                </Button>
              </Stack>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </Box>
  );
}
