import { useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { getNetworksQueryOptions, getTariffPlansQueryOptions } from "@core/apis/queries";

type AddTariffPlanToNetworkProps = {
  networkUid?: string | null;
  tariffPlanUid?: string | null;
  isOpen: boolean;
  closeModal: (arg0: any) => void;
};

const AddTariffPlanToNetwork = ({
  networkUid: initialNetworkUid,
  tariffPlanUid: initialTariffPlanUid,
  isOpen,
  closeModal,
}: AddTariffPlanToNetworkProps) => {
  // Client State
  const [contractType, seContractType] = useState<string | null>("STANDARD");
  const [validFrom, setValidFrom] = useState<DateTime | null>(DateTime.now());
  const [validTo, setValidTo] = useState<DateTime | null>();
  const [networkUid, setNetworkUid] = useState(initialNetworkUid);
  const [tariffPlanUid, setTariffPlanUid] = useState(initialTariffPlanUid);

  const { data: networks } = useQuery(getNetworksQueryOptions());

  const { data: tariffPlans } = useQuery(getTariffPlansQueryOptions());

  const handleAddTariffPlanToNetwork = async () => {
    closeModal({
      networkUid,
      tariffPlanUid,
      contractType,
      validFrom,
      validTo,
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="add-tariffPlan-to-network-dialog-title"
      aria-describedby="add-tariffPlan-to-network-dialog-description"
    >
      <DialogTitle>
        Add Tariff Plan to Network
        <Button onClick={closeModal}>Close</Button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select a network and a tariffPlan to add to the network.
        </DialogContentText>
        <Stack spacing={2} py={2}>
          {/* Network Select - disabled if networkUid is provided */}
          <Autocomplete
            disabled={!!initialNetworkUid}
            options={networks || []}
            getOptionLabel={(option) => option.displayName}
            value={networks?.find((network) => network.uid === networkUid) || null}
            onChange={(_, newValue) => setNetworkUid(newValue?.uid)}
            renderInput={(params) => <TextField {...params} label="Network" />}
          />
          {/* TariffPlan Select - disabled if tariffPlanUid is provided */}
          <Autocomplete
            disabled={!!initialTariffPlanUid}
            options={tariffPlans || []}
            getOptionLabel={(option) => option.name}
            value={tariffPlans?.find((tariffPlan) => tariffPlan.uid === tariffPlanUid) || null}
            onChange={(_, newValue) => setTariffPlanUid(newValue?.uid)}
            renderInput={(params) => <TextField {...params} label="Tariff Plan" />}
          />
          {/* Contract Type */}
          <TextField
            label="Contract Type"
            value={contractType}
            onChange={(e) => seContractType(e.target.value)}
          />
          <DatePicker
            label="Valid From"
            value={validFrom}
            onChange={(date) => setValidFrom(date)}
          />
          <DatePicker label="Valid To" value={validTo} onChange={(date) => setValidTo(date)} />
        </Stack>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button onClick={handleAddTariffPlanToNetwork}>Add TariffPlan</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddTariffPlanToNetwork;
