import { Box, Button, Typography } from "@mui/material";
import { faSpaceShuttle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFileRoute, Link } from "@tanstack/react-router";

export const Route = createFileRoute("/")({
  component: Home,
});

function Home() {
  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h2">Greetings, young Jedi!</Typography>
      <Typography variant="subtitle1" pb={2}>
        May the Force be with you!
      </Typography>
      {/* Go To App */}
      <Button
        component={Link}
        to="/app"
        variant="contained"
        startIcon={<FontAwesomeIcon icon={faSpaceShuttle} />}
      >
        Take me to the hyperspace!
      </Button>
    </Box>
  );
}
