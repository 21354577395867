import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { faChevronCircleDown } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "@tanstack/react-router";
import { uidToHexColor } from "@utils/index";
import { DateTime } from "luxon";

import { NetworkTariffPlan, TariffPlan } from "@core/models";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

const TariffPlanCard = ({ plan }: { plan: TariffPlan | NetworkTariffPlan }) => {
  const copyValueToClipboard = async (value: string) => {
    await navigator.clipboard.writeText(value);
  };
  return (
    <Box sx={{ minWidth: 275, maxWidth: 300 }} key={plan.uid}>
      <Card>
        <CardContent>
          <Stack alignItems="flex-start">
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography
                variant="h5"
                component="div"
                sx={{ textWrap: "pretty", maxWidth: 200 }}
                gutterBottom
              >
                {plan.name}
              </Typography>
              <Box
                sx={{ width: 15, height: 15, bgcolor: uidToHexColor(plan.uid) }}
                borderRadius={3}
              />
            </Box>
            <Button size="small" onClick={() => copyValueToClipboard(plan.uid)}>
              <Typography
                sx={{
                  mb: 1.5,
                  pb: 0.5,
                  fontSize: 12,
                  textTransform: "lowercase",
                  maxWidth: 200,
                }}
                noWrap
                color="text.secondary"
                variant="body2"
              >
                {plan.uid}
              </Typography>
            </Button>
            {(plan.validFrom || plan.validTo) && (
              <Box pb={2}>
                <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 200 }}>
                  <b>Date From:</b> {DateTime.fromISO(plan.validFrom!).toLocaleString()}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 200 }}>
                  <b>Date To:</b>{" "}
                  {plan.validTo ? DateTime.fromISO(plan.validTo!).toLocaleString() : "N/A"}
                </Typography>
              </Box>
            )}
            <Accordion sx={{ width: 250 }} elevation={3}>
              <AccordionSummary expandIcon={<FontAwesomeSvgIcon icon={faChevronCircleDown} />}>
                <Typography>Frontend Features</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {plan.frontendFeatures.map((feature) => (
                    <ListItem key={feature} disableGutters>
                      <ListItemText primary={feature} primaryTypographyProps={{ noWrap: true }} />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Stack>
        </CardContent>
        <CardActions>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button component={Link} size="small" to={`/app/tariff-plans/${plan.uid}`}>
              Investigate
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default TariffPlanCard;
