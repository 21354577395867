import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControl,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { faEye, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createFileRoute, useParams } from "@tanstack/react-router";
import { AddTariffPlanToNetwork, TariffPlanCard, UnstyledLink } from "@ui/components";
import { handleALL_CAPS_HELL } from "@utils/index";
import { DateTime } from "luxon";

import MdslApi from "@core/apis/mdsl.api";
import {
  getAvailableFrontendFeatures,
  getNetworkTariffPlansQueryOptions,
  getSingleNetworkQueryOptions,
} from "@core/apis/queries";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

const SingleNetworkPage = () => {
  // Client State
  const { queryClient } = Route.useRouteContext();
  const [isAddTariffToNetworkOpen, setIsAddTariffPlanToNetworkOpen] = useState(false);
  const [hasDuplicateFeaturePermission, setHasDuplicateFeature] = useState(false);
  // Route State
  const { networkId } = useParams({ strict: false });

  const { data: networkData } = useQuery(getSingleNetworkQueryOptions(networkId));
  const { data: availableFeatures, isLoading: availableFeaturesLoading } = useQuery(
    getAvailableFrontendFeatures()
  );

  const { data: networkTariffPlansData, isLoading: networkTariffPlansLoading } = useQuery(
    getNetworkTariffPlansQueryOptions(networkId)
  );

  // For every tariff plan, check if the network has a duplicate feature
  useEffect(() => {
    if (networkTariffPlansData) {
      const permissonsArray: string[] = [];
      networkTariffPlansData.forEach((tariffPlan) => {
        permissonsArray.push(...tariffPlan.frontendFeatures);
      });
      const uniquePermissions = Array.from(new Set(permissonsArray));
      setHasDuplicateFeature(uniquePermissions.length !== permissonsArray.length);
    }
  }, [networkTariffPlansData]);

  // Server State + Mutations
  const { mutate: addTariffPlanToNetwork } = useMutation({
    mutationFn: async ({
      networkUid,
      tariffPlanUid,
      validFrom,
      validTo,
      contractType,
    }: {
      networkUid: string;
      tariffPlanUid: string;
      validFrom: DateTime | null;
      validTo: DateTime | null;
      contractType: string;
    }) => {
      return MdslApi.addTariffPlanToNetwork({
        networkUid,
        tariffPlanUid,
        validFrom: validFrom?.startOf("day").toISO(),
        validTo: validTo?.startOf("day").toISO(),
        contractType,
      });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["tariffPlans", "networks", networkId] }),
  });

  const handleAddTariffPlanToNetwork = async (updateValues?: {
    networkUid: string;
    tariffPlanUid: string;
    validFrom: DateTime | null;
    validTo: DateTime | null;
    contractType: string;
  }) => {
    if (updateValues) {
      await addTariffPlanToNetwork({
        ...updateValues,
      });
    }
    setIsAddTariffPlanToNetworkOpen(false);
  };

  if (!networkData) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Typography variant="h2">Network Info</Typography>
      {hasDuplicateFeaturePermission && (
        <Box p={2}>
          <Alert severity="warning">
            This network has duplicate features in its tariff plans. So removing a tariff plan may
            not remove the required features.
          </Alert>
        </Box>
      )}
      <Grid container alignItems="stretch" gap={2}>
        <Grid borderRadius={2} sx={{ backgroundColor: "grey.100" }} xs={2} minWidth={200}>
          <Stack spacing={2} p={2}>
            <Typography variant="h6">Network Details</Typography>
            <FormControl>
              <TextField label="Network ID" value={networkData.uid} disabled />
            </FormControl>
            <FormControl>
              <TextField label="Network Name" value={networkData.name} disabled />
            </FormControl>
            <FormControl>
              <TextField label="Network Display Name" value={networkData.displayName} />
            </FormControl>
          </Stack>
        </Grid>
        <Grid borderRadius={2} sx={{ backgroundColor: "grey.100" }} xs>
          {/* Tariff Plans */}
          <Box p={2}>
            <Typography variant="h4">Tariff Plans</Typography>
            {networkTariffPlansLoading && <div>Loading...</div>}
            <Box display="flex" flexWrap="wrap" gap={1} justifyContent="stretch" p={2}>
              {networkTariffPlansData &&
                networkTariffPlansData.map((tariffPlan) => (
                  <Card key={tariffPlan.uid} variant="outlined">
                    <CardContent>
                      <Stack sx={{ width: 160 }} spacing={1}>
                        <Typography variant="body1" noWrap>
                          {tariffPlan.name}
                        </Typography>
                        <Typography variant="caption">
                          From: {DateTime.fromISO(tariffPlan.validFrom).toLocaleString()}
                        </Typography>
                        <Typography variant="caption">
                          To:{" "}
                          {tariffPlan.validTo
                            ? DateTime.fromISO(tariffPlan.validTo).toLocaleString()
                            : "N/A"}
                        </Typography>
                      </Stack>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="outlined"
                        component={UnstyledLink}
                        to={`/app/tariff-plans/${tariffPlan.uid}`}
                        startIcon={<FontAwesomeSvgIcon icon={faEye} />}
                      >
                        View
                      </Button>
                    </CardActions>
                  </Card>
                ))}
              <AddTariffPlanToNetwork
                isOpen={isAddTariffToNetworkOpen}
                closeModal={handleAddTariffPlanToNetwork}
                networkUid={networkId}
              />
              <Card variant="outlined">
                <CardContent>
                  <Stack sx={{ width: 160 }} spacing={1}>
                    <Typography variant="body1" noWrap>
                      Add Tariff Plan
                    </Typography>
                  </Stack>
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    onClick={() => setIsAddTariffPlanToNetworkOpen(true)}
                    color="success"
                    startIcon={<FontAwesomeSvgIcon icon={faPlus} />}
                  >
                    Add
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Box>
        </Grid>
        <Grid borderRadius={2} sx={{ backgroundColor: "grey.100" }} xs>
          {availableFeaturesLoading && <div>Loading...</div>}
          <Stack spacing={2} p={2}>
            <Typography variant="h6">Effective Network Features</Typography>
            <Grid container flexDirection="row" gap={2} flexWrap="wrap">
              <List
                sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
                subheader={<ListSubheader>Features</ListSubheader>}
              >
                {availableFeatures &&
                  availableFeatures.map((feature) => (
                    <ListItem key={feature.name}>
                      <ListItemIcon>
                        <Switch
                          edge="end"
                          disabled
                          // onChange={handleToggle("wifi")}
                          checked={networkData.frontendFeatures.some(
                            (f) => f.name === feature.feature
                          )}
                          inputProps={{
                            "aria-labelledby": `switch-list-label-${feature.name}`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`switch-list-label-${feature.name}`}
                        primary={handleALL_CAPS_HELL(feature.feature || feature.name)}
                      />
                    </ListItem>
                  ))}
              </List>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export const Route = createFileRoute("/app/_layout/networks/$networkId")({
  component: SingleNetworkPage,
  loader: async (opts) => {
    // Fancy little piece of code that ensures the networks data is loaded before rendering the component
    const data = await opts.context.queryClient.ensureQueryData(
      getSingleNetworkQueryOptions(opts.params.networkId)
    );
    return {
      data,
    };
  },
});
