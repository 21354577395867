export const uidToHexColor = (uid: string) => {
  // This is a fun little function that converts a uid string to a hex color
  // To be used for generating a color for the card. We do this to ensure uniqueness
  // but consistency for the cards.
  let hash = 0;
  for (let i = 0; i < uid.length; i++) {
    hash = uid.charCodeAt(i) + ((hash << 5) - hash);
  }
  // HSV
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 70%)`;
};


// Turns "SCENARIO_ANALYSIS" to "Scenario Analysis" etc. Proper capitalization
export const handleALL_CAPS_HELL = (str: string) =>
  str
    .replace(/_/g, " ")
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
