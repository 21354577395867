/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from "react";
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui";
import { Check } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { Box, Stack, styled } from "@mui/system";
import { faEye, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";

import MdslApi from "@core/apis/mdsl.api";
import { getNetworksQueryOptions } from "@core/apis/queries";
import { Network } from "@core/src/models";
import { FontAwesomeSvgIcon } from "@shared/ui/FontAwesomeSvgIcon";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: 6,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
      marginLeft: -2,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
      marginLeft: 4,
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : active ? (
        // If just active, spinner
        <CircularProgress size={18} color="inherit" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

type NetworkFormType = {
  networkName: string;
  managementLevel: number;
  resourceLevel: number;
  weatherCoordinates: string;
  timezone: string;
  tzName: string;
  countryCode: string;
  country: string;
  city: string;
  currency: string;
  displayName?: string;
  ignore: Record<string, string>;
  userIds: string[];
};

const NewNetworkForm = () => {
  const formContext = useForm<NetworkFormType>({
    defaultValues: {
      networkName: "",
      displayName: "",
      managementLevel: 4,
      resourceLevel: 4,
    },
  });

  const [formState, setFormSubmittedState] = useState<NetworkFormType>();
  const [activeStep, setActiveStep] = useState(0);

  const handleFormSubmit = (data: NetworkFormType) => {
    setFormSubmittedState(data);
  };

  // Steps

  const addNewGroupMutation = useMutation({
    mutationFn: async ({
      networkName,
      managementLevel,
      resourceLevel,
    }: Partial<NetworkFormType>) => {
      return MdslApi.createNetworkGroup(networkName, managementLevel, resourceLevel);
    },
  });

  const addNewPolicyMutation = useMutation({
    mutationFn: async ({ groupUid, policyName }: { groupUid: string; policyName: string }) => {
      return MdslApi.createNetworkPolicy(groupUid, policyName);
    },
  });

  const createNetworkMutation = useMutation({
    mutationFn: async ({ networkName, policyUid }: { networkName: string; policyUid: string }) => {
      return MdslApi.createNetwork(networkName, policyUid);
    },
  });

  const updateDisplayNameMutation = useMutation({
    mutationFn: async ({
      networkUid,
      displayName,
    }: {
      networkUid: string;
      displayName: string;
    }) => {
      return MdslApi.updateNetworkDisplayName(networkUid, displayName);
    },
  });

  const steps = [
    { id: 1, name: "Submit Form", description: "Submitting the form." },
    { id: 2, name: "Create Group", description: "Creating the base group for the network." },
    { id: 3, name: "Create Policy", description: "Creating the base policy for the network." },
    { id: 4, name: "Create Network", description: "Creating the network." },
    { id: 5, name: "Set Display Name (Optional)", description: "Setting the display name." },
  ];

  useEffect(() => {
    if (formState && !addNewGroupMutation.isPending && !addNewGroupMutation.isSuccess) {
      addNewGroupMutation.mutate(formState);
      setActiveStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, addNewGroupMutation.isPending]);

  useEffect(() => {
    if (
      addNewGroupMutation.isSuccess &&
      !addNewPolicyMutation.isPending &&
      !addNewPolicyMutation.isSuccess
    ) {
      addNewPolicyMutation.mutate({
        groupUid: addNewGroupMutation.data?.uid,
        policyName: formState?.networkName!,
      });
      setActiveStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewGroupMutation.isSuccess, addNewPolicyMutation.isPending, formState]);

  useEffect(() => {
    if (
      addNewPolicyMutation.isSuccess &&
      !createNetworkMutation.isPending &&
      !createNetworkMutation.isSuccess
    ) {
      createNetworkMutation.mutate({
        networkName: formState?.networkName!,
        policyUid: addNewPolicyMutation.data?.uid!,
      });
      setActiveStep(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addNewPolicyMutation.isSuccess, createNetworkMutation.isPending, formState]);

  useEffect(() => {
    if (
      createNetworkMutation.isSuccess &&
      !updateDisplayNameMutation.isPending &&
      !updateDisplayNameMutation.isSuccess
    ) {
      updateDisplayNameMutation.mutate({
        networkUid: createNetworkMutation.data?.uid!,
        displayName: formState?.displayName!,
      });
      setActiveStep(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNetworkMutation.isSuccess, updateDisplayNameMutation.isPending, formState]);

  useEffect(() => {
    if (updateDisplayNameMutation.isSuccess) {
      console.log("Network created successfully");
      setActiveStep(5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDisplayNameMutation.isSuccess]);

  return (
    <Grid container spacing={2}>
      {/* Required Steps */}
      <Grid xs={6}>
        <Typography variant="h3">Required Steps</Typography>
        <Typography variant="body1">
          {activeStep === 0
            ? "Please fill out the form and submit."
            : steps[activeStep - 1].description}
        </Typography>
        <Stepper activeStep={activeStep} connector={<QontoConnector />} orientation="vertical">
          {steps.map((label) => (
            <Step key={label.id}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label.name}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>

      <Grid xs={6}>
        {/* Form */}
        <FormContainer formContext={formContext} onSuccess={handleFormSubmit}>
          <Stack spacing={1} p={2}>
            <Typography variant="h3">Network Form</Typography>
            <Box flexDirection="row" display="flex" justifyContent="space-between">
              <TextFieldElement
                label="Network Name"
                name="networkName"
                required
                fullWidth
                autoFocus
              />
              <TextFieldElement label="Display Name" name="displayName" required fullWidth />
            </Box>
            <Box flexDirection="row" display="flex" justifyContent="space-between">
              <TextFieldElement
                label="Management Level"
                name="managementLevel"
                required
                fullWidth
              />
              <TextFieldElement label="Resource Level" name="resourceLevel" required fullWidth />
            </Box>
            <Box flexDirection="row" display="flex" justifyContent="flex-end">
              <Button variant="outlined" color="primary" type="submit">
                Add Network
              </Button>
            </Box>
          </Stack>
        </FormContainer>
      </Grid>
    </Grid>
  );
};

const Networks = () => {
  // Client State
  const [addNewNetworkState, setAddNewNetworkState] = useState(false);
  // Server State
  const {
    isPending: isNetworksPending,
    data: networksData,
    error: networksError,
  } = useQuery(getNetworksQueryOptions());

  return (
    <Box>
      <Dialog
        open={addNewNetworkState}
        onClose={() => setAddNewNetworkState(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Add New Network</DialogTitle>
        <DialogContent>
          <NewNetworkForm />
        </DialogContent>
      </Dialog>

      <Typography variant="h2">Networks</Typography>
      {isNetworksPending && <p>Loading...</p>}
      {networksError && (
        <Alert severity="error">
          <Typography variant="body1">{networksError.message}</Typography>
        </Alert>
      )}
      {networksData && (
        <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
          {networksData.map((value: Network) => (
            <ListItem
              key={value.uid}
              secondaryAction={
                <IconButton aria-label="view" component={Link} to={`/app/networks/${value.uid}`}>
                  <FontAwesomeSvgIcon icon={faEye} />
                </IconButton>
              }
            >
              <ListItemText primary={value.name} />
            </ListItem>
          ))}
          <ListItem
            key="add-new-network"
            secondaryAction={
              <IconButton
                aria-label="add-new-network"
                color="success"
                onClick={() => setAddNewNetworkState(true)}
              >
                <FontAwesomeSvgIcon icon={faPlus} />
              </IconButton>
            }
          >
            <ListItemText primary="Add new network" />
          </ListItem>
        </List>
      )}
    </Box>
  );
};

export const Route = createFileRoute("/app/_layout/networks/")({
  component: Networks,
  loader: async (opts) => {
    // Fancy little piece of code that ensures the networks data is loaded before rendering the component
    try {
      await opts.context.queryClient.ensureQueryData(getNetworksQueryOptions());
    } catch (error) {
      console.error("Error loading networks", error);
    }
  },
});
